<template>
  <b-card>
    <b-card-title class="mb-1">
      VENTAS POR MES
    </b-card-title>
    <b-table
      responsive="sm"
      :fields="tableColumns"
      :items="itemsMonth.months"
    />
    <b-card-footer class="text-right">
      <h4>Total: {{ itemsMonth.total }} </h4>
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BTable, BCard, BCardTitle, BCardFooter,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BCard,
    BCardTitle,
    BCardFooter,
  },
  props: {
    itemsMonth: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tableColumns: [
        { key: 'month', label: 'Mes', sortable: false },
        {
          key: 'total_format',
          label: 'Total',
          sortable: false,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
      ],
      items: [],
    }
  },
  computed: {
  },
}
</script>

<style scoped>

</style>
